.text-h1{
    color:rgb(9 52 52);
    text-align: center;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    /* text-decoration: underline  rgb(9 52 52);  */
    background-color: #fecd08;
    margin:20px 0px;
    padding: 10px 0px;
}
/* .animation:hover{
    overflow: hidden;
    transform: scale(1) translateY(-4%);
    cursor: pointer;
    
  }
.card{
        transition-duration: 0.8s;
        cursor: pointer;
}
.card:hover{
    transform: scale(1.05);
      z-index: 0;
  }   */
  .icons{
    font-size: 2rem;
    color: rgb(9 52 52);
    font-family: 'Lato',sans-serif;
  }
   /* .icons:hover{
    font-size: 2rem;
    color:  #fecd08;
  } */