

.header2 {
  display: flex;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  align-items: center;
  box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.box1 {
  flex: 4;
  display: flex;
  padding: 20px 0px 20px 40px;
  align-items: center;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.box2 {
  flex: 3;
  margin: 0;
  display: flex;
  padding: 20px 40px;
  align-items: center;
}

.text1 {
  font-size: 17px;
  font-style: normal;
  font-weight: bold;
  color: #333333;
  /* margin-right: 95px; */
}

.location {
  width: 100%;
  flex: 1;
  height: 70px;
  display: flex;
  padding: 0px 40px;
  align-items: center;
  justify-content: center;
}

.location-button {
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding-left: 0;
  flex: 1;
  width: 100%;
  /* border: 1px solid #fecd08; */
  display: flex;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;

  align-items: center;

  justify-content: center;
  position: relative;
  box-sizing: border-box;

  background-color: transparent;

  /* /* border-radius: 4px  */
}

@media (min-width: 900px) {
  .location-button {
    padding: 10px;
  }
}

.button-address {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: row;
}

.button-shoping-bag {
  fill: #fecd08;
  margin: 0px 20px;
  display: flex;
  align-items: center;
}

.button-location {
  flex: 1;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
}

.text3 {
  color: #fecd08;
  overflow: hidden;
  /* max-width: 90%;
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis; */
  font-size: 18px;
  font-weight: bold;
  color: #fecd08;
  text-align: left;
  font-family: Lato, sans-serif;
}

.text4 {
  color: #6a6c6e;
  overflow: hidden;
  max-width: 90%;
  min-width: 0;
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media (min-width: 600px) {
  .text3 {
    font-size: 17px;
    font-style: normal;
    font-weight: bold;
  }

  .text4 {
    font-size: 17px;
    font-style: normal;
    font-weight: normal;
  }
}

.sub-box2 {
  position: relative;
  flex: 1 1 0%;
  overflow: hidden;
}

.sub-sub-box2 {
  gap: 16px;
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}

.select {
  gap: 16px;
  flex: 2;
  display: flex;
  max-height: 100px;
  flex-direction: row;
}

.select-section {
  width: 100%;
}

.sub-select-section {
  display: flex;
  flex: 2 1 0%;
}

/* .caret {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  transition: transform 0.3s ease;
}

.caret.down {
  border-top: 6px solid black;
}

.caret.up {
  border-bottom: 6px solid #fecd08;
} */

.selection {
  padding: 12px 20px;
  border-radius: 8px;
  width: 100%;
}

.header2-sign-up {
  display: none;
}

.sub-header {
  display: none;
}

@media (max-width: 900px) {
  .header2 {
    width: 100%;
    display: inline-block;
  }

  .text1 {
    display: none;
  }

  .box1 {
    padding: 10px 0px;
  }

  .box2 {
    padding: 0px 15px 16px 15px;
  }

  .location-button {
    padding: 10px 5px;
  }

  .location {
    padding: 0px 15px;
  }

  .header2-sign-up {
    font-size: 20px;
    font-weight: bold;
    display: block;
    border: 2px solid #fecd08;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    border-radius: 50px;
    /* gap: 20px; */
    background-color: #fecd08;
  }

  .sub-header {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding: 13px 28px 0px 16px;
  }

  .header2-text-sign-up {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    padding-left: 4px;
    color: #093455;
  }

  .text2 {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    color: #333333;
  }
}
@media (max-width: 500px) {
  .header2 {
    width: 100%;
    display: inline-block;
  }

  .text1 {
    display: none;
  }

  .box1 {
    padding: 10px 0px;
  }

  .box2 {
    padding: 0px 15px 16px 15px;
  }

  .location-button {
    padding: 10px 5px;
  
  }

  .location {
    /* padding: 0px 15px; */
    margin: 20px 10px;
  }

  .header2-sign-up {
    font-size: 20px;
    font-weight: bold;
    display: block;
    border: 2px solid #fecd08;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 16px;
    border-radius: 50px;
    /* gap: 20px; */
    background-color: #fecd08;
  }

  .sub-header {
    display: block;
    display: flex;
    justify-content: space-between;
    align-items: center;
   padding: 13px 28px 0px 16px;
  }

  .header2-text-sign-up {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    padding-left: 4px;
    color: #093455;
  }

  .text2 {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    color: #333333;
  }
}
.button-location .text3:focus {
  border: 2px solid #fecd08;
  outline: none; /* To remove the default outline */
}
.iconright{
  color:#ccd4e0;
}


