.term-text {
        color:rgb(9 52 52);
        text-align: center;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: bold;
        /* text-decoration: underline  rgb(9 52 52); */
        background-color: #fecd08;
        margin:20px 0px;
        padding: 10px 0px;
        cursor: pointer;
    }


strong {
    color: rgb(9, 52, 85);
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    padding-bottom: 3px;
    text-decoration:underline rgb(9, 52, 85) ;
    cursor: pointer;
}

strong:hover {
    color: #fecd08;
    text-decoration:underline #fecd08 ;
}

.termdiv {
    padding: 50px;
    margin-left: auto;
    margin-right: auto;
    text-align: justify;
}