.Add{
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    color:rgb(9 52 52);
    cursor: pointer;
}
.text-h1{
    color:rgb(9 52 52);
    text-align: center;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: bold;
    /* text-decoration: underline  rgb(9 52 52); */
    background-color: #fecd08;
    margin:20px 0px;
    padding: 10px 0px;
    cursor: pointer;
}

/* .loader-line {
    width: 100%;
    height: 8px;
    position: relative;
    overflow: hidden;
    margin: 100px auto;
    border-radius: 30px;
}

.loader-line:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 40%;
    background-color: rgb(240, 197, 70);
    animation: lineAnim 1s linear infinite;
    border-radius: 20px;
}


@keyframes lineAnim {
    0% {
        left: -50%;
        width: 40%;
    }
    50% {
        width: 90%;
    }
    100% {
        left: 100%;
        width: 100%;
        height: 20px;
    }
} */