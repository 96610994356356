
.navbar {
  padding: 0px;
  box-shadow: 0 4px 22px 0 rgba(65, 64, 66, 0.15);
}

.img-con {
  display: flex;
  padding: 0 0  2px 30px;
}

.header-text {
  font-size: 24px;
  color: #fecd08;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  /* margin-left: -80px; */
}

.jss72 {
width: 100px;
height: 90px;
}

.jss516 {
  display: flex;
  justify-content: center;
}

.sign-up {
  background-color: white;
  height: 100%;
  padding: 24px 24px;
  display: flex;
  justify-content: center;
  align-items: center;
   font-family: "Lato", sans-serif;
}
.sign-up1{
  background-color: white !important;
  height: 100% !important;
  /* padding: 24px 227px !important; */
  width: 550px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
   font-family: "Lato", sans-serif !important; 
}
.sub-sign-up {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fecd08;
  border-radius: 50px;
  gap: 15px;
  padding: 6px 16px;
}
.sub-sign-up1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #fecd08; */
  border-radius: 50px;
  /* gap: 15px; */
  padding: 6px 16px;
  /* margin-top: 20px; */
}
.text-sign-in {
  font-size: 17px;
  font-weight: bold;
  color: #fecd08;
}
.text-sign-in1 {
  font-size: 14px;
  font-weight: bold;
  color: rgb(51, 51, 51);
}

.sign-up2{
  display: none !important;
}
@media (max-Width:1200px) {
  .sign-up1{
     width: 70px;
   
  }
  .text-sign-in1 {
   display: none;
  }
  .sign-up3{
    display: none !important;
  }
  .sign-up2{
    display: block !important;
  }
  .sub-sign-up1{
    margin-top: 20px;
    flex-direction: row;
  }
}

@media (min-Width:901px) and (max-Width:992px) {
  .jss72 {
    width: 45%;
  }
  
  .jss516 {
    display: flex;
    justify-content: center;
  }
  
  .sign-up {
    background-color: white;
    height: 100%;
    padding: 19px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
     font-family: "Lato", sans-serif;
  }
  
  .sub-sign-up {
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid #fecd08;
    border-radius: 50px;
    gap: 15px;
    padding: 6px 16px;
  }
  .text-sign-in {
    font-size: 17px;
    font-weight: bold;
    color: #fecd08;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto
}
.img-con {
  display: flex;
  justify-content: start;
  padding: 0 30px  2px 30px;
}
}
@media (max-width: 900px) {
  .sign-up {
    background-color: white;
    height: 100%;
    padding: 45px 95px;
    display: none;
  }
  /* .img-con {
    padding: 15px 0px;
    width: 25%;
  } */
}

@media (max-width: 420px) {
  .jss72 {
    width: 80%;
  }
  .img-con {
    width: 130px;
    padding: 0  0 2px 20px;
  }
}
.profile{
  color: #333333;
  font-size: 17px;
  font-style: normal;
  font-weight: normal;
  text-decoration: none;
}