
body {
    font-family: "Lato", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.nav-tabs .nav-link.active{
      border-radius: 999px !important;
      /* padding-inline: 66px !important; */
      color: white !important;
      background: #fecd08 !important;
      margin-bottom: 5px;
      
     
}
.LoginReg-tabs{
    border-radius: 999px;
    max-width: fit-content;
    align-items: center;
    /* border: 1px solid red; */
    margin-left: 60px;
    padding-left: 9px;
    padding-right: 9px;
    background-color: #efefef;
    padding-top: 5px;
}
.nav-tabs .nav-link{
    width: 165px !important;
    color: black;
    font-weight: 400;
}


.nav-tabs .nav-link:hover{
   border-radius: 999px;
    
}
@media (max-Width:430px)  {
    .nav-tabs .nav-link{
        width: 100px !important;
        text-align: center;
        font-size: 12px;
    }
    .nav-tabs .nav-link.active{
        text-align: center !important;
    
    }
}
@media (max-Width:900px){
    .LoginReg-tab{
        margin-left: 189px !important;
    }
    }

 @media (max-Width:700px){
    .LoginReg-tab{
        margin-left: 122px !important;
    }
    } 
.modal-title{
    flex: 1;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    font-weight: normal;
}
