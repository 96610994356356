/* HTML: <div class="loader"></div> */
.loadersus {
    width: 60px;
    aspect-ratio: 1;
    --c:no-repeat linear-gradient(rgb(152, 219, 169) 0 0);
    background:
      var(--c) left   20px top    0,
      var(--c) top    20px right  0,
      var(--c) right  20px bottom 0,
      var(--c) bottom 20px left   0;
    background-size:calc(100%/3) calc(100%/3);
    animation: 
      l29-1 .75s infinite alternate linear,
      l29-2 1.5s infinite;
  }
  @keyframes l29-1 {
   90%,100% {background-size:calc(2*100%/3) calc(100%/3),calc(100%/3) calc(2*100%/3)}
  }
  @keyframes l29-2 {
   0%,49.99% {transform:scaleX(1)}
   50%,100%  {transform:scaleX(-1)}
  }